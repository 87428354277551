@import '../../../../../../../../styles/shared';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 20px);
  margin-bottom: 20px;
  background: radial-gradient(
      120.63% 120.63% at 5.77% -20.63%,
      rgba(176, 26, 164, 0.5) 10.67%,
      rgba(176, 26, 164, 0) 100%
    ),
    var(--color-scheme-primary-main);
  border-radius: 16px;
  overflow: hidden;

  @include mq($from: tablet) {
    height: calc(100% - 40px);
    margin-bottom: 40px;
  }
}

.body {
  display: flex;
  gap: 20px;
  flex: 1 0;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  flex: 1 0;
  padding: 45px 30px;
}

.title {
  font-family: var(--hub-module-heading-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;

  @include mq($from: tablet) {
    margin-bottom: 5px;
    font-size: 26px;
    line-height: 48px;
  }
}

.text {
  font-size: 20px;
  font-weight: 300;

  @include mq($from: tablet) {
    font-size: 22px;
  }

  p {
    line-height: 32px;
  }
}

.sponsorLogos {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.sponsorLogosTitle {
  font-size: 9px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.18px;
  text-transform: uppercase;
}

.logos {
  display: flex;
  gap: 15px;
}

.logo {
  max-height: 50px;
}

.imageWrapper {
  display: flex;
  justify-content: flex-end;
  margin: -40px -84px 30px 0;

  @include mq($until: tablet) {
    display: none;
  }
}

.image {
  display: block;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  object-fit: cover;

  @include mq($from: desktop) {
    width: 380px;
    height: 380px;
  }
}

.fakeImage {
  @extend .image;
  background: #c4c4c4;
}

.footer {
  padding: 16px 30px;
  color: #dadada;
  background: rgba(255, 255, 255, 0.1);
}

.summary {
  font-size: 14px;
  line-height: 16px;
}

.regular {
  .logo {
    &:not(:first-child) {
      display: none;
    }
  }
}

.featured {
  .content {
    @include mq($from: tablet) {
      padding-top: 55px;
    }
  }

  .title {
    @include mq($from: tablet) {
      font-size: 40px;
    }
  }

  @include mq($until: tablet) {
    .logo {
      &:not(:first-child) {
        display: none;
      }
    }
  }
}
